<template>
  <div>
    <div class="page-box scihub-p">
      <!-- <div class="scihub-address">SCI-HUB地址：<a target="_blank" style="text-decoration:underline;" :href="domainListUrl">{{domainListUrl}}</a></div> -->
      <div class="scihub-update">
        <span class="label">全局sci-hub地址:</span>
        <span v-for="item in domainList" :key="item" class="domain">
          <input :value="item" type="radio" v-model="globalDomain" disabled/>
          <label :for="item" class="label">{{item}}</label>
        </span>
      </div>
      <div class="scihub-set">
        <span class="label">更新本地sci-hub地址:</span>
        <y-select
          class="address-select"
          v-model="currentDomain"
          :data="domainList"
          @on-change="OnDomainChange"
          placeholder="请选择"></y-select>
        <!-- <a class="label-test" target="_blank" :href="'https://'+currentDomain+'/https://www.ncbi.nlm.nih.gov/pubmed/31760857'">点击测试</a> -->
        <a class="label-test" target="_blank" :href="'https://'+currentDomain+'/10.1111/his.14160'">点击测试</a>
      </div>
    </div>
  </div>
</template>
<script>
import YSelect from '../common/select/select'
import Domain from './domain'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: { YSelect },
  mixins: [Domain],
  data () {
    return {
      domainListUrl: 'http://tool.yovisun.com/scihub/',
      currentDomain: '',
      globalDomain: '',
      domainList: []
    }
  },
  watch: {
    globalDomain (val, oldVal) {
      this.updateDomain(val)
      this.updateLocalDomain(val)
      if (oldVal) {
        this.currentDomain = val
      }
    }
  },
  mounted () {
    document.title = 'SCI-HUB地址'
    this.loadDomainList()
    this.loadCurrentDomain()
  },
  methods: {
    loadDomainList () {
      this.$http.get(`${this.httpRoot}/scihub/domain/list`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.domainList = res.domainList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    updateDomain (domain) {
      this.$http.post(`${this.httpRoot}/scihub/domain/update`, {domain}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          //
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    OnDomainChange () {
      this.updateLocalDomain(this.currentDomain)
    }
  }
}
</script>
<style lang="less">
.scihub-p {
  padding-top: 20px;
  .label {
    margin-right: 10px;
  }
  .label-test {
    margin-left: 10px;
    text-decoration: underline;
  }
  .scihub-address, .scihub-update {
    height: 50px;
    line-height: 50px;
  }
  .scihub-set {
    margin-top: 10px;
    height: 32px;
    line-height: 32px;
    display: flex;
    .address-select {
      width: 250px;
    }
  }
}
</style>
